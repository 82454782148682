.hover:hover {
    cursor: pointer !important;
}


/*Custom Scroll bar*/

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #a9a9a9;
    border-radius: 8px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.stepActiveIcon {
  color: #CC4229 !important;
}
.stepIcon {
  color: #E0826D !important;
}

.stepCompletedIcon {
  color: #CC4229 !important;
}